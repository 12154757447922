import { amgApi } from "@/service/axios";

class ClientService {
  async getClients(body, page) {
    try {
      const data = await amgApi.post(
        `clients/search-clients?page=${page}`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getClients:", error);
      throw error;
    }
  }
  async programs() {
    const data = await amgApi.get("/commons/programs");
    return data;
  }

  // Clients
  async usersPrograms(params) {
    const data = await amgApi.post("/commons/users-programs", params);
    return data.data;
  }

  async getAllAssessorsParagon() {
    const data = await amgApi.post("/paragon/get-all-assessors-paragon");
    return data.data;
  }

  async searchClientPayment(params) {
    const data = await amgApi.post("/clients/search-client-payment", params);
    return data;
  }

  async usersAllPrograms(params) {
    const data = await amgApi.post("/commons/users-all-programs", params);
    return data;
  }
  // Modal Send Sms
  async allSmsHistory(params) {
    const data = await amgApi.post("/messages/get-all-sms-history", params);
    return data;
  }

  async sendMessageClients(params) {
    const data = await amgApi.post(
      "/messages/send-mass-message-clients",
      params
    );
    return data;
  }
  // Modal Send Email
  async allQuicksEmail(params) {
    const data = await amgApi.post("/emails/all-quicks-email", params);
    return data;
  }

  async allEmailHistory(params) {
    const data = await amgApi.post("/emails/all-email-history", params);
    return data;
  }

  async deleteQuickEmail(params) {
    const data = await amgApi.post("/emails/delete-quick-email", params);
    return data;
  }

  async saveQuickEmail(params) {
    const data = await amgApi.post("/emails/save-quick-email", params);
    return data;
  }

  async sendEmail(params, onUploadProgress) {
    const data = await amgApi.post("/emails/filedragdropemail", params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  async getClientsWoAlert(body, page) {
    try {
      const data = await amgApi.post(`clients/search-clients-wo-alert`, body);
      return data.data;
    } catch (error) {
      console.log("Something went wrong on getClientsWoAlert:", error);
      throw error;
    }
  }
}
export default new ClientService();
