<template>
  <b-modal v-model="isActive" @hidden="closeMe" title="Tracking Change Address" title-tag="h3" size="lg" hide-footer>
      <b-row>
        <b-col>
          <h5>Client</h5>
          <p class="rounded text-primary border-primary font-medium-1 text-center py-1">
            {{ client }}
          </p>
        </b-col>
        <b-col></b-col>
      </b-row>
      <b-table :items="tracking" :fields="fields" show-empty>
          <template #cell(created_at)="{item}">
                <b-col>
                  <b-row>{{item.created_name_user}}</b-row>
                  <b-row>{{item.created_at | myGlobalWithHour}}</b-row>
                </b-col>
          </template>
          <template #cell(updated_at)="{item}">
                <b-col>
                  <b-row>{{item.updated_name_user}}</b-row>
                  <b-row>{{item.updated_at | myGlobalWithHour}}</b-row>
                </b-col>
          </template>
      </b-table>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    props:{active:{type:Boolean}},
    created(){
        this.isActive = this.active
    },
    data(){return{
        isActive:false,
        fields:[
          {key:'fields',label:'Address'},
          {key:'created_at',label:'Created At'},
          {key:'updated_at',label:'Updated At'},
        ],
    }},
    methods:{
        closeMe(){
          this.isActive = false
          this.$emit('close')
        }
    },
    computed:{
      ...mapGetters({
        client: 'ParagonClientDashboard/getClient',
        tracking: 'ParagonClientDashboard/getTrackingChangeAddress'
      }),
    }  
}
</script>