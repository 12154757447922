<template>
  <div class="d-flex justify-content-end">
    <court-info-action />
    <!-- Send sms -->
    <b-button
      :disabled="isResponsibleAccount"
      class="ml-1"
      variant="success"
      @click="openModalSendSms"
    >
      Send SMS <feather-icon icon="MessageSquareIcon" />
    </b-button>
    <b-button class="ml-1" variant="success" @click="openModalHistorySms">
      <tabler-icon icon="ListCheckIcon" />
    </b-button>
    <!-- Send email -->
    <b-button
      :disabled="isResponsibleAccount"
      class="ml-1"
      variant="success"
      @click="openModalSendEmail"
    >
      Send email <feather-icon icon="AtSignIcon" />
    </b-button>
    <b-button class="ml-1" variant="success" @click="openModalHistoryEmail">
      <tabler-icon icon="ListCheckIcon" />
    </b-button>

    <!-- Send Statement -->
    <b-button
      :disabled="isResponsibleAccount"
      class="ml-1"
      variant="success"
      @click="openModalSendStatement"
    >
      Send Statement <feather-icon icon="MessageSquareIcon" />
    </b-button>
    <b-button class="ml-1" variant="success" @click="openModalHistoryStatement">
      <tabler-icon icon="ListCheckIcon" />
    </b-button>

    <b-dropdown
      :disabled="isResponsibleAccount"
      right
      class="ml-1"
      variant="primary"
    >
      <template class="btn-primary" #button-content>
        <span>Options<tabler-icon class="mr-1" icon="ListCheckIcon" /></span>
      </template>
      <b-dropdown-item @click="openModalHistoryClaims"
        ><tabler-icon icon="GridIcon" class="mr-1" />Claims</b-dropdown-item
      >
      <b-dropdown-item @click="openShareModal"
        ><feather-icon icon="Share2Icon" class="mr-1" />Share</b-dropdown-item
      >
      <b-dropdown-item @click="openAddService">
        <feather-icon icon="FilePlusIcon" class="mr-1" />
        Add Service
      </b-dropdown-item>
      <b-dropdown-item>Documents</b-dropdown-item>
      <b-dropdown-item
        :disabled="!hasOthersPrograms"
        @click="openModalProgramList"
        ><feather-icon icon="AirplayIcon" class="mr-1" />Others
        Programs</b-dropdown-item
      >
      <b-dropdown-item>Payment Report</b-dropdown-item>
      <b-dropdown-item>Receipts</b-dropdown-item>
      <b-dropdown-item>New License</b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CourtInfoAction from "@/views/debt-solution/views/court-info/components/CourtInfoAction.vue";

export default {
  props: { hasOthersPrograms: { type: Boolean }, leadId: { type: Number } },
  components: { CourtInfoAction },

  methods: {
    openShareModal() {
      this.$emit("openShareModal");
    },
    openModalProgramList() {
      this.$emit("openModalProgramList");
    },
    openModalSendSms() {
      this.$emit("openModalSendSms");
    },
    openModalSendEmail() {
      this.$emit("openModalSendEmail");
    },
    openModalHistorySms() {
      this.$emit("openModalHistorySms");
    },
    openModalHistoryEmail() {
      this.$emit("openModalHistoryEmail");
    },
    openModalHistoryClaims() {
      this.$emit("openModalHistoryClaims");
    },
    openAddService() {
      this.$emit("openAddService");
    },
    openModalSendStatement() {
      this.$emit("openModalSendStatement");
    },
    openModalHistoryStatement() {
      this.$emit("openModalHistoryStatement");
    },
  },
  computed: {
    userAuthorized() {
      return !(
        this.currentUser.role_id === 1 || this.currentUser.role_id === 2
      );
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
};
</script>
<style scoped>
.content-header .content-header-right .light-blue {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}
</style>