<template>
  <b-modal v-model="isActive" @hidden="closeMe" :title="pack.name_benefit" title-tag="h3" hide-footer>
      <program-client-header :client="client" :program="program" />
      <b-row>
        <b-col>
          <b-form-group label="Commentary">
            <b-form-textarea v-model="commentary" rows="3" :disabled="!!pack.status"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col v-if="!!pack.status">
            <span>
              DONE: {{pack.user_id}} | {{pack.created_at | myGlobalWithHour}}
            </span>
        </b-col>
        <b-col v-else class=" d-flex justify-content-end">
            <b-button variant="success" @click="saveData"> Done </b-button>
        </b-col>
      </b-row>
  </b-modal>
</template>
<script>
//components
import ProgramClientHeader from '@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue'
//vuex
import { mapGetters,mapMutations } from 'vuex'
//service
import ClientDashboardService from '../../../services/dashboard.service'
export default {
  created(){
    if(this.pack.status){
        this.commentary = this.pack.commentary
    }
    this.isActive = this.active
  },
  props:{
      active:{type:Boolean},
      },
  components:{ProgramClientHeader},
  data(){return{
    isActive: false,
    commentary: '',

  }},
  methods:{
    async saveData(){
        const params = {
            id_account:this.accountId, id_benefit:this.pack.id, comment: this.commentary, id_user:this.currentUser.user_id
        }
        const confirm = await this.showConfirmSwal()
        if(confirm.isConfirmed){
            const result = await ClientDashboardService.saveBenefitAccount(params)
                if(result){
                    this.SET_STATUS_PACK(this.pack.id)
                    this.showSuccessSwal()
                    this.closeMe()
                }else{
                    this.showErrorSwal()
                }
        }
        
    },
    closeMe(){
        this.isActive = false,
        this.$emit('close')
    },
    ...mapMutations({
      SET_STATUS_PACK:'ParagonClientDashboard/SET_STATUS_PACK',
    })
  },
  computed:{
    ...mapGetters({
      currentUser: 'auth/currentUser',
      client: 'ParagonClientDashboard/getClient',
      accountId: 'ParagonClientDashboard/getAccountId',
      program:'ParagonClientDashboard/getProgram',
      pack: 'ParagonClientDashboard/getPack',
    })
  }
}
</script>