<template>
  <b-modal
    v-model="isActive"
    title="History Of Statement"
    title-tag="h3"
    size="lg"
    hide-footer
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
    scrollable
    @hidden="closeMe"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          History Of Statement
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeMe"
          />
        </div>
      </div>
    </template>
    <account-client-header-deb :client="client" />
    <b-table
      :items="getHistoryStatementClient"
      :fields="fields"
      show-empty
      sticky-header="50vh"
      class="table-new-customization custom-table"
    >
      <template #cell(request_by)="data">
        <div>{{ data.item.create_by }}</div>
        <div>{{ data.item.created_at | myGlobal }}</div>
        <div>{{ data.item.created_at | myHourTime }}</div>
      </template>
      <template #cell(content)="data">
        <div>{{ data.item.observation }}</div>
      </template>
      <template #cell(statement)="data">
        <a
          v-if="data.item.url_pdf != ''"
          target="_blank"
          :href="data.item.url"
          style="color: blue"
        >
          <amg-icon icon="FilePdfIcon" class="mr-1" /><span>Open</span></a
        >
      </template>

      <template #cell(type)="data">
        <span v-if="data.item.statement_client_type_id == 1">Email</span
        ><span v-else>Correspondence</span>
      </template>
      <template #cell(status)="data">
        <div v-if="data.item.status == 'SENT'" style="color: #00cc00">
          {{ data.item.status }}
        </div>
        <div v-if="data.item.status == 'PENDING'" style="color: red">
          {{ data.item.status }}
        </div>
        <div v-if="data.item.sent_date != null">
          {{ data.item.sent_date | myGlobal }}
        </div>
        <div v-if="data.item.sent_date != null">
          {{ data.item.sent_date | myHourTime }}
        </div>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import CoolLightBox from "vue-cool-lightbox";

import { mapGetters } from "vuex";
import AccountClientHeaderDeb from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/AccountClientHeaderDeb.vue";
import clientsDashboardServices from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";

export default {
  components: {
    AccountClientHeaderDeb,
    CoolLightBox,
  },
  props: {
    active: { type: Boolean },
  },
  data() {
    return {
      isActive: false,
      fields: [
        { key: "request_by", label: "Request By" },
        { key: "content", label: "Content", class: "w-25" },
        { key: "statement", label: "Statement" },
        { key: "type", label: "type" },
        { key: "status", label: "Status" },
      ],
      itemImage: [],
      showImage: null,
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
    }),
  },
  created() {
    this.isActive = this.active;
  },

  methods: {
    // get-history-statement-client
    async getHistoryStatementClient() {
      try {
        const { data } =
          await clientsDashboardServices.getHistoryStatementClient({
            id: this.$route.params.idClient,
          });
        this.history = data;
        return this.history;
      } catch (error) {
        console.log(error);
      }
    },
    closeMe() {
      this.isActive = false;
      this.$emit("close");
    },
    openImage(image) {
      this.itemImage.push(image);
      this.showImage = 0;
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
.cover-image {
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100px;
  border-radius: 10px;
  margin-top: 50%;
}
</style>
