<template>
  <b-modal
    v-model="isActive"
    @hidden="closeMe"
    title="History Of SMS"
    title-tag="h3"
    size="lg"
    hide-footer
    scrollable
  >
    <CoolLightBox
      class="align-items-center"
      :items="itemImage"
      :index="showImage"
      @close="showImage = null"
    />
    <account-client-header :client="client" :account="account" />
    <b-table :items="history" :fields="fields" show-empty sticky-header="50vh">
      <template #cell(user_name)="data">
        {{ data.item.user_name }} {{ data.item.created_at | myGlobalDay }}
      </template>
      <template #cell(content)="data">
        <div v-html="data.item.content"></div>
      </template>
      <template #cell(image)="data">
        <template v-if="data.item.image != null">
          <div
            class="cover-image clickable"
            @click="openImage(data.item.image)"
            :style="`background-image: url(${data.item.image})`"
          ></div>
        </template>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import CoolLightBox from "vue-cool-lightbox";
import AccountClientHeader from "@/views/commons/components/applications/views/components/others/AccountClientHeader.vue";
import { mapGetters } from "vuex";
export default {
  created() {
    this.isActive = this.active;
  },
  props: {
    active: { type: Boolean },
  },
  components: {
    AccountClientHeader,
    CoolLightBox,
  },
  data() {
    return {
      isActive: false,
      fields: [
        { key: "user_name", label: "Send By" },
        { key: "number", label: "Number", class: "w-25" },
        { key: "content", label: "Content" },
        { key: "image", label: "Image" },
      ],
      itemImage: [],
      showImage: null,
    };
  },
  methods: {
    closeMe() {
      this.isActive = false;
      this.$emit("close");
    },
    openImage(image) {
      this.itemImage.push(image);
      this.showImage = 0;
    },
  },
  computed: {
    ...mapGetters({
      account: "ParagonClientDashboard/getAccount",
      client: "ParagonClientDashboard/getClient",
      history: "ParagonClientDashboard/getHistorySms",
    }),
  },
};
</script>
<style scoped>
.cover-image {
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100px;
  border-radius: 10px;
  margin-top: 50%;
}
</style>