<template>
  <b-row
      v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
      class="content-header"
  >
    <!-- Content Left -->
    <b-col class="content-header-left" :class="{'mb-2': !noBorderBottom}" cols="12" md="5">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>

          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
                >{{ item.text }}</b-breadcrumb-item
              >
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col class="content-header-right text-md-right" :class="{'mb-2': !noBorderBottom}" md="7" cols="12">
      <slot name="actions" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "HeaderSlot",
  directives: {
    Ripple,
  },
  props: {
    noBorderBottom: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
};
</script>
