<template>
  <div class="mb-1 ml-3 mr-3">
    <h3 style="font-weight: bolder">COMPANY</h3>
    <hr class="border-2" />
    <b-row>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'Company Name:'"
            :model="company.companyName"
            :type="'text'"
            @save="saveNewCompanyData($event, 1)"
            @hover="searchTracking(1)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'Address:'"
            :model="company.bizType2"
            :type="'text'"
            @save="saveNewCompanyData($event, 4)"
            @hover="searchTracking(4)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'File Date:'"
            :model="company.fileDate"
            :type="'date'"
            @save="saveNewCompanyData($event, 8)"
            @hover="searchTracking(8)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'Mailing Address:'"
            :model="company.mailingAddress"
            :type="'text'"
            @save="saveNewCompanyData($event, 11)"
            @hover="searchTracking(11)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'Entity Number:'"
            :model="company.entityNumber"
            :type="'text'"
            @save="saveNewCompanyData($event, 16)"
            @hover="searchTracking(16)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'Phone:'"
            :model="company.phone"
            :type="'phone'"
            @save="saveNewCompanyData($event, 12)"
            @hover="searchTracking(12)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'EIN:'"
            :model="company.ein"
            :type="'number'"
            @save="saveNewCompanyData($event, 2)"
            @hover="searchTracking(2)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'Website:'"
            :model="company.webPage"
            :type="'text'"
            @save="saveNewCompanyData($event, 5)"
            @hover="searchTracking(5)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'Tax Status:'"
            v-if="currentUser.modul_id != 3"
            :model="company.taxStatus"
            :type="'text'"
            @save="saveNewCompanyData($event, 9)"
            @hover="searchTracking(9)"
          />
          <input-edit-view
            v-else
            :tittle="'Tax Status:'"
            :model="company.taxStatus"
            :type="'select'"
            :dataSelect="tax_status"
            @save="saveNewCompanyData($event, 9)"
            @hover="searchTracking(9)"
          >
          </input-edit-view>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'Email:'"
            :model="company.email"
            :type="'text'"
            @save="saveNewCompanyData($event, 13)"
            @hover="searchTracking(13)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'Business Type:'"
            v-if="currentUser.modul_id != 3"
            :model="company.bizType1"
            :type="'text'"
            @save="saveNewCompanyData($event, 3)"
            @hover="searchTracking(3)"
          />
          <input-edit-view
            v-else
            :tittle="'Business Type:'"
            :model="company.bizType1"
            :type="'select'"
            :dataSelect="business"
            @save="saveNewCompanyData($event, 3)"
            @hover="searchTracking(3)"
          >
          </input-edit-view>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'Facebook:'"
            :model="company.facebook"
            :type="'text'"
            @save="saveNewCompanyData($event, 14)"
            @hover="searchTracking(14)"
          />
        </b-form-group>
        <!-- <input-edit-view
          :tittle="'Toll Free:'"
          :model="company.tollFree"
          :type="'text'"
          @save="saveNewCompanyData($event, 7)"
          @hover="searchTracking(7)"
        /> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'FBN:'"
            :model="company.fbn"
            :type="'text'"
            @save="saveNewCompanyData($event, 10)"
            @hover="searchTracking(10)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <input-edit-view
            :tittle="'Instagram:'"
            :model="company.instagram"
            :type="'text'"
            @save="saveNewCompanyData($event, 15)"
            @hover="searchTracking(15)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
//vuex
import { mapActions, mapGetters, mapMutations } from "vuex";
//components
import InputEditView from "../utils/InputEditView.vue";
//service
import ClientDashboardService from "../../../services/dashboard.service";
export default {
  components: {
    InputEditView,
  },
  data() {
    return {
      ein: "",
      typee: 0,
      business: [],
      tax_status: [],
    };
  },
  methods: {
    async searchTracking(typee) {
      const params = { id_account: this.accountId, typee };
      if (this.typee === 0 || this.typee != typee) {
        this.typee = typee;
        await this.getTrackingCompanyData(params);
      }
    },
    async saveNewCompanyData(dataa, typee) {
      const parameters = {
        dataa,
        id_account: this.accountId,
        id_user: this.currentUser.user_id,
        typee,
      };

      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        const result = await ClientDashboardService.updateCompanyData(
          parameters
        );
        if (result) {
          if (typee === 11) this.SET_MAILING_ADDRESS(dataa);
          this.showToast("success", "top-right", "Success!", "CheckIcon");
        } else {
          this.showToast("danger", "top-right", "Oops!", "XIcon");
        }
      }
    },
    async getBusinessType() {
      this.business = [];
      const response = await ClientDashboardService.getBusinessType();
      this.business = response.data;
      let old = 0;
      this.business.forEach((element) => {
        if (element.text == this.company.bizType1) {
          old = 1;
        }
      });

      if (
        old == 0 &&
        this.company.bizType1 != null &&
        this.company.bizType1 != ""
      ) {
        this.business.push({
          value: this.company.bizType1,
          text: this.company.bizType1,
          disabled: true,
        });
      }
      // order by text
      this.business.sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      });
    },
    async getTaxStatus() {
      this.tax_status = [];
      const response = await ClientDashboardService.getTaxStatusType();
      this.tax_status = response.data;
      let old = 0;
      this.tax_status.forEach((element) => {
        if (element.text == this.company.tax_status) {
          old = 1;
        }
      });

      if (
        old == 0 &&
        this.company.tax_status != null &&
        this.company.tax_status != ""
      ) {
        this.tax_status.push({
          value: this.company.tax_status,
          text: this.company.tax_status,
          disabled: true,
        });
      }
    },
    ...mapActions({
      getTrackingCompanyData: "ParagonClientDashboard/getTrackingCompanyData",
    }),
    ...mapMutations({
      SET_MAILING_ADDRESS: "ParagonClientDashboard/SET_MAILING_ADDRESS",
    }),
  },
  computed: {
    ...mapGetters({
      company: "ParagonClientDashboard/getCompanyInputs",
      currentUser: "auth/currentUser",
      accountId: "ParagonClientDashboard/getAccountId",
    }),
  },
  created() {
    this.getBusinessType();
    this.getTaxStatus();
  },
};
</script>