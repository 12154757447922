<template>
  <b-modal v-model="isActive" @hidden="closeMe" size="lg" title="History Status" title-tag="h3" hide-footer>
      <program-client-header :program="program" :client="client" />
      <br>
      <b-table :items="historyStatus" :fields="fields" show-empty>
          <template #cell(status)="{item}">  
              <span>
                  {{ item.status | statusAccountClient}}
              </span>
          </template>
          <template #cell(created_at)="{item}">  
              <b-col>
                <b-row>{{item.creator_name}}</b-row>
                <b-row>{{item.created_at | myGlobalDay}}</b-row>
              </b-col>
          </template>
          <template #cell(updated_at)="{item}">  
              <b-col class="d-flex felx-column ">
                <b-row>{{item.updater_name}}</b-row>
                <b-row>{{item.updated_at | myGlobalDay}}</b-row>
              </b-col>
          </template>
      </b-table>
  </b-modal>
</template>
<script>
//components
import ProgramClientHeader from '@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue'
//vuex
import { mapGetters } from 'vuex'
export default {
  components:{ProgramClientHeader},
  data(){return{
    isActive: false,
    fields:[
      {key:'status',label:'Status'},
      {key:'observation',label:'Observation'},
      {key:'created_at',label:'Created By'},
      {key:'updated_at',label:'Updated By'},
    ]
  }},
  methods:{
    closeMe(){
      this.isActive = false
      this.$emit('closeMe')
    },
  },
  computed:{
    textStatus(){
             
          },
    ...mapGetters({
      program:'ParagonClientDashboard/getProgram',
      client:'ParagonClientDashboard/getClient',
      historyStatus: 'ParagonClientDashboard/getHistoryStatus',
      
    })
  },
  created(){
    this.isActive = true
  },
  
}
</script>