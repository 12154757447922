<template>
  <div class="px-1 w-100">
    <b-card no-body class="m-0">
      <div class="mb-1 ml-3 mr-3">
        <b-row v-if="packs" class="my-1">
          <h3 v-for="(pack, i) in packs" :key="i" class="mx-1">
            <b-badge
              :variant="pack.status ? 'light-info' : 'light-secondary'"
              class="px-1"
              :class="
                isResponsibleAccount && !pack.status ? '' : 'cursor-pointer'
              "
              pill
              @click="isResponsibleAccount && !pack.status ? '' : setPack(pack)"
            >
              {{ pack.name_benefit }}
            </b-badge>
          </h3>
        </b-row>
        <b-row>
          <b-col lg="auto">
            <b-skeleton-img v-if="!validateImg" width="80px" height="80px" />
            <b-img
              v-if="validateImg"
              :src="getProgramLogo(client.program_name)"
              thumbnail
              fluid
              style="max-width: 100px; max-height: 80px"
            />
          </b-col>
          <b-col lg="auto">
            <b-skeleton-wrapper :loading="!validateAccount">
              <template #loading>
                <b-card no-body>
                  <b-skeleton width="85%" style="min-width: 124px" />
                  <b-skeleton width="55%" style="min-width: 95px" />
                  <b-skeleton width="70%" style="min-width: 110px" />
                </b-card>
              </template>
            </b-skeleton-wrapper>
            <b-form-group
              style="margin-bottom: 5px !important; margin-top: 8px"
            >
              <template v-if="validateAccount">
                <span
                  v-if="!isResponsibleAccount"
                  style="font-weight: bolder; font-size: 20px"
                  class="cursor-pointer"
                  @click="openEditLead"
                  >{{ client.client }}</span
                >

                <span v-else style="font-weight: bolder; font-size: 20px">{{
                  client.client
                }}</span>
                <span
                  v-if="
                    $store.getters[
                      'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                    ].is_responsible
                  "
                  class="text-primary"
                >
                  <tabler-icon
                    :icon="
                      $store.getters[
                        'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                      ].is_responsible === currentUser.user_id
                        ? 'StarIcon'
                        : 'LockIcon'
                    "
                    size="15"
                  />
                  <strong>CEO's client</strong>
                </span>
                <StatusClientAccount />
              </template>
            </b-form-group>
            <div class="d-flex justify-content-between">
              <b-form-group
                style="margin-bottom: 5px !important; margin-top: 4px"
              >
                <template v-if="validateAccount">
                  <span style="font-size: 14.5px"
                    ><span
                      style="
                        font-weight: bolder;
                        font-size: 14px;
                        margin-right: 5px;
                      "
                      >Account:</span
                    >
                    {{ client.account }}</span
                  >
                  <br />
                </template>
              </b-form-group>
              <!-- <b-form-group class="ml-3">
                <b-input-group prepend="Monthly Payment: $" size="sm" disabled>
                  <b-form-input
                    v-model="client.monthlyAmount"
                    class="border-1"
                    disabled
                  />
                  <b-input-group-append>
                    <b-button
                      v-b-popover.hover.bottom="msgPopOver"
                      variant="outline-secondary"
                      class="px-1 py-0"
                    >
                      <feather-icon
                        :class="methodPayment"
                        icon="CreditCardIcon"
                        class="cursor-pointer"
                        size="20"
                      />
                      <span v-if="this.typePayment === 'PA'">
                        ({{ client.paymentDay }})
                      </span>
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      :disabled="isResponsibleAccount"
                      class="px-1 py-0"
                      @click="openModalPaymentsData"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="cursor-pointer"
                        size="20"
                      />
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      class="px-1 py-0"
                      @click="openModalHisitoryMonthlyPayments"
                    >
                      <feather-icon
                        icon="MoreVerticalIcon"
                        class="cursor-pointer"
                        size="20"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group> -->
              <b-form-group class="ml-3">
                <b-input-group size="sm">
                  <b-input-group-prepend class="w-35">
                    <b-input-group-text class="w-100 badge-name-group">
                      <span>Company Type</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-radio-group
                    v-model="client.company"
                    :disabled="hasFilled || isResponsibleAccount"
                    class="form-control text-center bg-transparent"
                  >
                    <template>
                      <b-form-radio
                        :value="1"
                        style="margin-left: 12px; margin-top: 4px"
                        ><span>CORP</span></b-form-radio
                      >
                      <b-form-radio
                        :value="2"
                        style="margin-left: 12px; margin-top: 4px"
                        ><span>LLC</span></b-form-radio
                      >
                    </template>
                  </b-radio-group>
                  <b-input-group-append>
                    <b-button
                      v-if="client.company === 2"
                      variant="primary"
                      :disabled="isResponsibleAccount"
                      @click="openModalFieldLLC"
                      >Filled <tabler-icon icon="BuildingIcon"
                    /></b-button>
                    <b-button
                      v-if="client.company === 1"
                      variant="primary"
                      :disabled="isResponsibleAccount"
                      @click="openModalFieldCORP"
                      >Filled <tabler-icon icon="BuildingIcon"
                    /></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12" />
          <b-col cols="12" class="mt-1">
            <b-row class="d-flex justify-content-around">
              <b-col cols="8">
                <b-row>
                  <b-col :cols="Boolean(client.itin) ? '3' : '6'" class="mb-1">
                    <b-input-group size="sm" disabled>
                      <b-input-group-prepend class="w-20">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>SSN: </span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="client.ssn"
                        class="border-1"
                        disabled
                      />
                    </b-input-group>
                  </b-col>
                  <b-col v-if="Boolean(client.itin)" cols="3" class="mb-1">
                    <b-input-group size="sm" disabled>
                      <b-input-group-prepend class="w-20">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>ITIN: </span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="client.itin"
                        class="border-1"
                        disabled
                      />
                    </b-input-group>
                  </b-col>
                  <b-col cols="6" class="mb-1">
                    <b-input-group size="sm" disabled>
                      <b-input-group-prepend class="w-20">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>Address: </span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="address"
                        class="border-1"
                        disabled
                      />
                      <b-input-group-append>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          class="cursor-pointer"
                          size="25"
                          @click="openModalTrackingChangeAddress"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col cols="6" class="mb-1">
                    <b-input-group size="sm" disabled>
                      <b-input-group-prepend class="w-20">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>DOB: </span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        :value="client.dob | myGlobal"
                        class="border-1"
                        disabled
                      />
                    </b-input-group>
                  </b-col>
                  <b-col cols="6" class="mb-1">
                    <b-input-group size="sm" disabled>
                      <input-edit-view
                        :tittle="'Mailing Address:'"
                        :model="company.mailingAddress"
                        :type="'text'"
                        @save="saveNewCompanyData($event, 11)"
                        @hover="searchTracking(11)"
                      />
                    </b-input-group>
                  </b-col>
                  <b-col cols="6">
                    <b-input-group size="sm" disabled>
                      <b-input-group-prepend class="w-20">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>Email: </span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="client.email"
                        class="border-1"
                        disabled
                      />
                    </b-input-group>
                  </b-col>
                  <b-col cols="6">
                    <b-input-group size="sm" disabled>
                      <b-input-group-prepend class="w-20">
                        <b-input-group-text class="w-100 badge-name-group">
                          <span>Phone: </span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="client.phone"
                        class="border-1"
                        disabled
                      />
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Memo">
                  <div
                    v-if="!isMemoActive"
                    class="memo-class"
                    @click="isResponsibleAccount ? '' : changeStatusMemo(true)"
                    v-html="msgMemo"
                  />
                  <b-textarea
                    v-else
                    v-model="msgMemo"
                    class="d-flex justify-content-between w-100"
                  />
                </b-form-group>

                <b-row v-if="isMemoActive">
                  <b-col
                    ><b-button
                      variant="danger"
                      class="w-100"
                      @click="changeStatusMemo(false)"
                      ><feather-icon icon="XIcon" size="20" /></b-button
                  ></b-col>
                  <b-col
                    ><b-button variant="success" class="w-100" @click="editMemo"
                      ><feather-icon icon="CheckIcon" size="20" /></b-button
                  ></b-col>
                </b-row>
                <p v-else class="text-right">
                  Updated by: {{ memo.user }} |
                  {{ memo.date | myGlobalWithHour }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <company-information />

      <hr class="border-2" />
      <b-row class="ml-2 mr-2">
        <b-col cols="12">
          <b-nav pills class="ml-3 mb-0">
            <b-nav-item
              v-b-tooltip.hover.bottomright="'Payments'"
              :to="
                $route.matched[0].meta.general
                  ? { name: 'bussiness-client-task-notes-general' }
                  : { name: 'bussiness-client-task-notes' }
              "
              exact
              exact-active-class="active border-radius-tabs"
              :link-classes="['px-3', bgTabsNavs]"
              >Task / Notes</b-nav-item
            >
            <b-nav-item
              v-b-tooltip.hover.bottomright="'Payments'"
              :to="
                $route.matched[0].meta.general
                  ? { name: 'bussiness-client-payments-general' }
                  : { name: 'bussiness-client-payments' }
              "
              exact
              exact-active-class="active border-radius-tabs"
              :link-classes="['px-3', bgTabsNavs]"
              >Payments</b-nav-item
            >
            <b-nav-item
              v-b-tooltip.hover.bottomright="'Files'"
              :to="
                $route.matched[0].meta.general
                  ? { name: 'bussiness-client-o-files-general' }
                  : { name: 'bussiness-client-o-files' }
              "
              exact
              exact-active-class="active border-radius-tabs"
              :link-classes="['px-3', bgTabsNavs]"
              >Files
            </b-nav-item>
            <b-nav-item
              v-b-tooltip.hover.bottomright="'Access Credentials'"
              :to="
                $route.matched[0].meta.general
                  ? { name: 'bussiness-client-access-credentials-general' }
                  : { name: 'bussiness-client-access-credentials' }
              "
              exact
              exact-active-class="active border-radius-tabs"
              :link-classes="['px-3', bgTabsNavs]"
              >ACr</b-nav-item
            >
            <b-nav-item
              v-b-tooltip.hover.bottomright="'Call Log'"
              :to="
                $route.matched[0].meta.general
                  ? { name: 'bussiness-client-call-log-general' }
                  : { name: 'bussiness-client-call-log' }
              "
              exact
              :active="isCallLog"
              exact-active-class="active border-radius-tabs"
              :link-classes="['px-3', bgTabsNavs]"
              >Call Log</b-nav-item
            >
            <b-nav-item
              v-if="validationArrRoles"
              v-b-tooltip.hover.bottomright="'Customer Tickets'"
              :to="
                $route.matched[0].meta.general
                  ? { name: 'client-bussiness-tickets-general' }
                  : { name: 'client-bussiness-tickets' }
              "
              exact
              exact-active-class="active border-radius-tabs"
              :link-classes="['px-3', bgTabsNavs]"
              >Customer Tickets
              <span
                v-if="
                  G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 0
                "
                class="ml-1"
              >
                <feather-icon
                  icon
                  :badge="
                    G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 99
                      ? '99+'
                      : G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open
                  "
                  badge-classes="badge-important"
                />
              </span>
            </b-nav-item>
          </b-nav>
          <b-card class="border-top-primary border-3 border-table-radius px-0">
            <router-view :key="$route.path" />
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <br />
    <b-row />
    <modal-history-status
      v-if="activeModalHistory"
      @closeMe="closeModalHistory"
    />
    <modal-benefit
      v-if="activeModalBenefit"
      :active="activeModalBenefit"
      @close="closeModalBenefit"
    />
    <modal-tracking-change-address
      v-if="activeTrackingChangeAddress"
      :active="activeTrackingChangeAddress"
      @close="closeModalTrackingChangeAddress"
    />
    <modal-history-payments
      v-if="activeModalHisitoryMonthlyPayments"
      :active="activeModalHisitoryMonthlyPayments"
      @close="closeModalHisitoryMonthlyPayments"
    />
    <modal-monthly-edit
      v-if="activeModalPaymentsData"
      @close="closeModalPaymentsData"
    />
    <lead-update
      v-if="isAddUpdateUserSidebarActive"
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      @refresh="refresh"
    />

    <modal-field-llc
      v-if="activeModalFieldLLC"
      @whenClose="activeModalFieldLLC = false"
      @whenOk="activeModalFieldLLC = false"
    />
    <corp-modal
      v-if="activeModalFieldCorp"
      @whenClose="activeModalFieldCorp = false"
      @whenOk="closeCorpModal"
    />
    <!-- <modal-payments v-if="activeModalPaymentsData" :active="activeModalPaymentsData" @close="closeModalPaymentsData" /> -->
  </div>
</template>
<script>
// vuex
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
// services
// components
import VMoney from "v-money";
import ModalMonthlyEdit from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalMonthlyEdit.vue";
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate.vue";
import ClientDashboardService from "@/views/paragon/views/clients/dashboard/services/dashboard.service";
import ModalFieldLlc from "@/views/paragon/views/clients/components/LlcModal.vue";
import CorpModal from "@/views/paragon/views/clients/components/CorpModal.vue";
import IconStatusAccount from "../utils/IconStatusAccount.vue";
// modals
import ModalHistoryStatus from "../modals/ModalHistoryStatus.vue";
import ModalBenefit from "../modals/ModalBenefit.vue";
import ModalTrackingChangeAddress from "../modals/ModalTrackingChangeAddress.vue";
import ModalHistoryPayments from "../modals/ModalHistoryPayments.vue";
import StatusClientAccount from "@/views/administration/views/clients/components/StatusClientAccount.vue";
// import ModalPayments from '../modals/ModalPayments.vue'
// service
import CompanyInformation from "./CompanyInformation.vue";
import InputEditView from "../utils/InputEditView.vue";

export default {
  components: {
    // ModalPayments,
    ModalMonthlyEdit,
    ModalHistoryPayments,
    ModalTrackingChangeAddress,
    ModalBenefit,
    ModalHistoryStatus,
    IconStatusAccount,
    VMoney,
    LeadUpdate,
    CompanyInformation,
    ModalFieldLlc,
    CorpModal,
    InputEditView,
    StatusClientAccount,
  },
  directives: { money: VMoney },
  data() {
    return {
      service: "",
      msgMemo: "",
      msgPopOver: "",
      isMemoActive: false,
      activeModalHistory: false,
      activeModalBenefit: false,
      activeTrackingChangeAddress: false,
      activeModalHisitoryMonthlyPayments: false,
      activeModalPaymentsData: false,
      isAddUpdateUserSidebarActive: false,
      toEdit: false,
      activeModalFieldLLC: false,
      activeModalFieldCorp: false,
      taskToSend: {},
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      validateImg: false,
      validateAccount: false,
      editSidebar: "first",
      typeEdit: "lead",
      hasFilled: false,
    };
  },
  methods: {
    async searchTracking(typee) {
      const params = { id_account: this.accountId, typee };
      if (this.typee === 0 || this.typee != typee) {
        this.typee = typee;
        await this.getTrackingCompanyData(params);
      }
    },
    async saveNewCompanyData(dataa, typee) {
      const parameters = {
        dataa,
        id_account: this.accountId,
        id_user: this.currentUser.user_id,
        typee,
      };

      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        const result = await ClientDashboardService.updateCompanyData(
          parameters
        );

        if (result) {
          if (typee === 11) this.SET_MAILING_ADDRESS(dataa);
          this.showToast("success", "top-right", "Success!", "CheckIcon");
        } else {
          this.showToast("danger", "top-right", "Oops!", "XIcon");
        }
      }
    },
    closeCorpModal() {
      this.activeModalFieldCorp = false;
      this.refresh();
    },
    async refresh() {
      this.addPreloader();

      const data = await this.$store.dispatch(
        "ParagonClientDashboard/getClientData",
        this.$route.params.idClient
      );
      this.hasFilled = this.client.company != null;
      this.service = this.client.services[0]?.name
        ? this.client.services[0]?.name
        : "";
      this.removePreloader();
    },
    async openEditLead() {
      this.addPreloader();
      await this.getLeadEdit();
      if (this.editSidebar === "first") {
        await Promise.all([
          this.getPrograms(),
          this.getStateLeads(),
          this.getStatusLeads(),
          this.getSourceLeads(),
          this.getSourceNames(),
          this.getStates(),
          this.getEeuuStates(),
          this.getCountries(),
          this.getOwners(),
        ]);
        this.editSidebar = "second";
      }
      if (this.S_LEAD_EDIT) {
        this.isAddUpdateUserSidebarActive = true;
      }
      this.removePreloader();
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.client.lead_id });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async openModalPaymentsData() {
      await this.getPaymentData(this.accountId);
      this.activeModalPaymentsData = true;
    },
    async openModalFieldLLC() {
      this.activeModalFieldLLC = true;
    },
    async openModalFieldCORP() {
      this.activeModalFieldCorp = true;
    },
    closeModalPaymentsData() {
      this.activeModalPaymentsData = false;
    },
    async openModalHisitoryMonthlyPayments() {
      await this.getHistoryMonthlyPayments(this.accountId);
      this.activeModalHisitoryMonthlyPayments = true;
    },
    closeModalHisitoryMonthlyPayments() {
      this.activeModalHisitoryMonthlyPayments = false;
    },
    changeStatusMemo(status) {
      if (!status) {
        this.msgMemo = this.memo.msg;
      }
      this.isMemoActive = status;
    },
    async openHistoryStatusModal() {
      await this.getHistoryStatus(this.accountId);
      this.activeModalHistory = true;
    },
    closeModalHistory() {
      this.activeModalHistory = false;
    },
    openModalBenefit() {
      this.activeModalBenefit = true;
    },
    closeModalBenefit() {
      this.activeModalBenefit = false;
    },
    async openModalTrackingChangeAddress() {
      const params = {
        id_module: this.moduleId,
        id_lead: this.leadId,
        typee: 1,
      };
      await this.getTrackingChangeAddress(params);
      this.activeTrackingChangeAddress = true;
    },
    closeModalTrackingChangeAddress() {
      this.activeTrackingChangeAddress = false;
    },
    async editMemo() {
      const params = {
        id: this.client.accountId,
        memo: this.msgMemo,
        user_id: this.currentUser.user_id,
      };
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        const status = await ClientDashboardService.editMemo(params);
        if (status) {
          this.showSuccessSwal();
          const date = Date.now();
          this.SET_MEMO({
            msg: this.msgMemo,
            user: this.currentUser.fullName,
            date,
          });
        } else {
          this.showErrorSwal();
        }

        this.isMemoActive = false;
      }
    },
    setPack(data) {
      this.SET_PACK(data);
      this.openModalBenefit();
    },

    ...mapActions({
      getHistoryStatus: "ParagonClientDashboard/getHistoryStatus",
      getTrackingChangeAddress:
        "ParagonClientDashboard/getTrackingChangeAddress",
      getTaskData: "ParagonClientDashboard/getTaskData",
      getHistoryMonthlyPayments:
        "ParagonClientDashboard/getHistoryMonthlyPayments",
      getPaymentData: "ParagonClientDashboard/getPaymentData",
      getTrackingCompanyData: "ParagonClientDashboard/getTrackingCompanyData",

      // EditLead
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
    }),
    ...mapMutations({
      SET_MEMO: "ParagonClientDashboard/SET_MEMO",
      SET_PACK: "ParagonClientDashboard/SET_PACK",
      SET_MAILING_ADDRESS: "ParagonClientDashboard/SET_MAILING_ADDRESS",
    }),
  },
  computed: {
    validationArrRoles() {
      if (
        this.currentUser.arrRoles.find(
          (rol) =>
            (rol.role_id === 2 || rol.role_id === 1 || rol.role_id === 6) &&
            rol.module_id !== 24 &&
            rol.user_id !== 38
        )
      ) {
        return true;
      }
      return false;
    },
    methodPayment() {
      switch (this.typePayment) {
        case "MA":
          this.msgPopOver = "Manual";
          return "text-primary";
          break;
        case "OT":
          this.msgPopOver = "Others";
          return "text-warning";
          break;
        case "PA":
          this.msgPopOver = "Automatic";
          return "text-success";
          break;
      }
    },
    onlyRead() {
      return this.moduleId === 12;
    },
    taskParams() {
      return {
        id: this.accountId,
        user_id: this.currentUser.user_id,
      };
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    address() {
      return `${this.client.address.street || ""} ${
        this.client.address.city || ""
      }, ${this.client.address.states_eeuu_slug || ""}, ${
        this.client.address.zipCode || ""
      }`;
    },
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      memo: "ParagonClientDashboard/getMemo",
      client: "ParagonClientDashboard/getPersonalInputs",
      accountId: "ParagonClientDashboard/getAccountId",
      leadId: "ParagonClientDashboard/getLeadId",
      packs: "ParagonClientDashboard/getPacks",
      skin: "appConfig/skin",
      typePayment: "ParagonClientDashboard/getTypePayment",
      company: "ParagonClientDashboard/getCompanyInputs",
      G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
    }),
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },

    isCallLog() {
      if (this.$route.matched[2]) {
        const { callConversationRoute, callNoConversationRoute } =
          this.$route.matched[2].meta;
        return [callConversationRoute, callNoConversationRoute].includes(
          this.$route.name
        );
      }
      return false;
    },
  },
  async created() {
    await this.refresh();
    this.msgMemo = this.memo.msg;
    this.validateImg = true;
    this.validateAccount = true;
  },
  async mounted() {
    await this.A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD({
      user_id: this.currentUser.user_id,
      client_account_id: this.$route.params.idClient,
    });
  },
};
</script>
<style scoped>
.memo-class {
  height: 58px;
  width: 100%;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.438rem 1rem;
  overflow: auto;
  white-space: pre-wrap;
}
</style>
