var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"History Of Payments","title-tag":"h3","size":"xl"},on:{"hidden":_vm.closeMe},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('program-client-header',{attrs:{"client":_vm.client,"program":_vm.program}}),_c('b-table',{attrs:{"items":_vm.history,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(monthly_amount)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" $ "+_vm._s(item.monthly_amount)+" ")])]}},{key:"cell(method_payment)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.methodPayment(item.method_payment))+" ")])]}},{key:"cell(type_payment)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.typePayment(item.type_payment))+" ")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(item.created_at))+" ")])]}},{key:"cell(updated_at)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(item.updated_at))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }