<template>
  <div>
    <b-nav class="m-0" pills>
      <b-nav-item
        :to="
          moduleId === 3
            ? { name: 'bussiness-client-information' }
            : { name: 'client-information' }
        "
        exact
        :exact-active-class="isInformationActive"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Information
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary">
      <router-view />
    </b-card>
  </div>
</template>
<script>
export default {
  methods: {},
  computed: {
    isInformationActive() {
      return this.$route.name === "client-information" ||
        this.$route.name === "bussiness-client-information"
        ? "active"
        : "inactive";
    },
    isTaskNotesActive() {
      return this.$route.name === "client-task-notes" ||
        this.$route.name === "bussiness-client-task-notes"
        ? "active"
        : "inactive";
    },
    isPaymentsActive() {
      return this.$route.name === "client-payments" ||
        this.$route.name === "bussiness-client-payments"
        ? "active"
        : "inactive";
    },
    isFilesActive() {
      return this.$route.name === "client-o-files" ||
        this.$route.name === "bussiness-client-o-files"
        ? "active"
        : "inactive";
    },
    isAccessCredentialsActive() {
      return this.$route.name === "client-access-credentials" ||
        this.$route.name === "bussiness-client-access-credentials"
        ? "active"
        : "inactive";
    },
    isScoresActive() {
      return this.$route.name === "client-scores" ||
        this.$route.name === "bussiness-client-scores"
        ? "active"
        : "inactive";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>