export default [
  {
    key: "date_tk",
    label: "Date",
    class: "text-left",
    visible: true,
  },
  {
    key: "level_name",
    label: "Level",
    class: "text-left",
    visible: true,
  },
  {
    key: "status",
    label: "Status",
    class: "text-left",
    visible: true,
  },
  {
    key: "user",
    label: "User",
    class: "text-left",
    visible: true,
  },
  {
    key: "description",
    label: "Description",
    class: "text-left",
    visible: true,
  },
]
