import { amgApi } from "@/service/axios";
class ClientDashboardService {
  async getClientData(id) {
    try {
      const { data } = await amgApi.post("commons/search-account", { id });
      return data[0];
    } catch (err) {
      console.log(err);
    }
  }
  async getHistoryStatus(id) {
    //recibe client account id
    try {
      const { data } = await amgApi.post("paragon/get-history-status", { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async getCompanyData(id_account) {
    try {
      const { data } = await amgApi.post("paragon/get-company-data", {
        id_account,
      });
      return data[0];
    } catch (err) {
      console.log(err);
    }
  }
  async getTaskData(parameters) {
    // recibe 2 parametros: id:(client account id) user_id:( id de usuario actual)
    try {
      const { data } = await amgApi.post("paragon/get-task-data", parameters);
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async getNotesData(parameters) {
    //recibe 3 parametros: id:(client account id) year:(year actual o a busacar) iduser:(id de usuario actual)
    try {
      const { data } = await amgApi.post("paragon/get-note-data", parameters);
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async editMemo(parameters) {
    // recibe 3 parametros: id:(client_account_id) memo: (mensaje) user_id: (id de usuario actual)
    try {
      const { status } = await amgApi.post("paragon/edit-memo", parameters);
      return !!status;
    } catch (err) {
      console.log(err);
    }
  }
  async updateCompanyData(parameters) {
    try {
      /* recibe 4 parametros: 
                dataa: (mensaje)
                id_account: (client account id)
                id_user: (id de usuario actual)
                typee: (indice del input a actualizar del 1 al 7)
            */
      const { status } = await amgApi.post(
        "paragon/update-company-data",
        parameters
      );
      return status === 200;
    } catch (err) {
      console.log(err);
    }
  }
  async getCharges(id) {
    try {
      const { data } = await amgApi.post("paragon/get-charges", { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async getPayments(id) {
    try {
      const { data } = await amgApi.post("paragon/get-payments", { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async getScores(id) {
    //recibe lead_id
    try {
      const { data } = await amgApi.post("paragon/get-scores", { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async getHistorySms(parameters) {
    //recibe 2 parametros: id(client account id) type: 0
    try {
      const { data } = await amgApi.post("paragon/get-history-sms", parameters);
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async getHistoryEmail(id) {
    //recibe id(client account id)
    try {
      const { data } = await amgApi.post("paragon/get-history-email", { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async saveBenefitAccount(parameters) {
    //recibe 4 parametros id_account(client account id), id_benefit, comment, id_user (id usuario actual)
    try {
      const { status } = await amgApi.post(
        "paragon/save-benefit-account",
        parameters
      );
      return status;
    } catch (err) {
      console.log(err);
    }
  }
  async getTrackingChangeAddress(parameters) {
    //recibe 3 parametros id_lead, typee:1 , id_module
    try {
      const { data } = await amgApi.post(
        "paragon/get-tracking-change-address",
        parameters
      );
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async getTrackingCompanyData(parameters) {
    // recibe 2 parametros : id_account , typee
    try {
      const { data } = await amgApi.post(
        "paragon/get-tracking-company-data",
        parameters
      );
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async getHistoryMonthlyPayments(id) {
    //recibe id (account id)
    try {
      const { data } = await amgApi.post(
        "paragon/get-history-monthly-payments",
        { id }
      );
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async getPaymentsData(id) {
    //recibe id (account id)
    try {
      const { data } = await amgApi.post("paragon/get-payments-data", { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  async getBusinessType() {
    try {
      const response = await amgApi.get("business/get-business-type");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async getTaxStatusType() {
    try {
      const response = await amgApi.get("business/get-tax-status-type");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ClientDashboardService();
