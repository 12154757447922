<template>
  <div>
    <b-modal
      v-model="ownControl"
      modal
      centered
      title="History Claims"
      size="xl"
      modal-class="custom-modal-amg"
      hide-footer
      title-tag="h3"
      header-bg-variant="transparent"
      header-class="p-0"
      :no-close-on-backdrop="true"
      @hidden="closeModalHistory"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>History Claims</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModalHistory"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <b-container fluid>
        <div v-if="dataclient !== ''">
          <b-row class="client-info">
            <b-col cols="12" md="3" class="p-0">
              <div
                class="mr-1"
                :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
              >
                <p>Client | {{ dataclient.client }}</p>
              </div>
            </b-col>
            <b-col cols="12" md="3" class="p-0">
              <div
                class="mx-1"
                :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
              >
                <p>Account | {{ dataclient.account }}</p>
              </div>
            </b-col>
            <b-col cols="12" md="3" class="p-0">
              <div
                class="mx-1"
                :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
              >
                <p>Advisor | {{ dataclient.advisor || "None" }}</p>
              </div>
            </b-col>
            <b-col cols="12" md="3" class="p-0">
              <div
                class="ml-1"
                :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
              >
                <p>Sup/Chief | {{ dataclient.chief }}</p>
              </div>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col cols="12" md="4">
              <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
                <p
                  class="m-0"
                  style="gap: 5px"
                >
                  Client Name | {{ dataclient.client }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="3">
              <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
                <p
                  class="m-0"
                  style="gap: 5px"
                >
                  Account | {{ dataclient.account }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="5">
              <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
                <p
                  class="m-0"
                  style="gap: 5px"
                >
                  Advisor / Sup-Chief | {{ dataclient.advisor || "None" }} | {{ dataclient.chief }}
                </p>
              </div>
            </b-col>
          </b-row> -->
        </div>

        <hr />
        <div class="d-flex justify-content-end">
          <b-button
            size="md"
            class="mb-1"
            variant="info"
            :disabled="isResponsibleAccount"
            @click="openCreateNewClaim"
          >
            New Claim
          </b-button>
        </div>
        <!-- Table -->
        <b-table
          ref="updatesChargeListTable"
          :items="searchHistoryCoApplicant"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          show-empty
          sticky-header="70vh"
          class="table-new-customization custom-table"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(type_claim)="{ item }">
            <small class="d-block">
              <strong>Type: </strong>{{ item.type_claim }}
            </small>
            <small class="d-block">
              <strong>Source: </strong>{{ item.source_claim }}
            </small>
            <small class="d-block">
              {{ item.level_name }}
            </small>
          </template>
          <template #cell(reasons)="{ item }">
            <div
              id="badge-reasons"
              class="d-flex flex-row flex-wrap justify-content-left m-0"
              style="gap: 2px"
            >
              <b-badge
                id="badge-reason"
                v-for="reason in reasonData(item)"
                :key="reason.id"
                variant="light-info"
              >
                {{ reason.name }}
              </b-badge>
            </div>
          </template>
          <template #cell(created_at)="{ item }">
            <span class="d-block">{{ item.creator_of_the_registry }}</span>
            <span class="d-block"
              >{{ item.created_at | myGlobalWithHour }}
            </span>
          </template>
          <template #cell(priority)="{ item }">
            <b-badge :variant="variantPriority(item.priority)">
              {{ item.priority }}
            </b-badge>
          </template>
          <template #cell(tracking)="data">
            <div>
              <feather-icon
                icon="ListIcon"
                @click="modalTracking(data.item)"
                size="20"
                class="cursor-pointer text-primary"
              />
            </div>
          </template>

          <template #cell(status)="{ item }">
            <div>
              <b-badge :variant="badgeColor(item.status)">{{
                item.status
              }}</b-badge>
            </div>
          </template>

          <template #cell(days_passed)="{ item }">
            <b-badge variant="light-primary">
              {{ item.days_passed }}
            </b-badge>
          </template>

          <template #cell(action)="data">
            <div class="d-flex flex-row justify-content-around">
              <feather-icon
                icon="EyeIcon"
                size="20"
                class="d-flex flex-column text-info cursor-pointer"
                @click="openModalDetail(data.item)"
                v-b-tooltip.hover
                title="View Detail"
              />
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>
    <ModalTracking
      v-if="showModalTracking"
      @close="closeTrackingModal"
      :claimId="claimId"
      :advisorName="advisor"
      :clientName="client"
    ></ModalTracking>

    <ModalDetail
      :selectedClaim="selectedItemClaim"
      :show="showModalDetailClaim"
      v-if="showModalDetailClaim"
      @close="closeModalDetail"
    />
    <ModalCreateClaim
      v-if="showModalClient"
      @save="showModalClient = false"
      :client="dataclient"
      @close="showModalClient = false"
    />
  </div>
</template>

<script>
// Import Services
import ModalNewClaim from "@/views/debt-solution/views/claims/components/ModalNewClaim.vue";
import ModalTracking from "@/views/commons/components/claims_v2/components/Modals/ModalTracking.vue";
import ModalCreateClaim from "@/views/commons/components/claims_v2/components/Modals/ModalCreateClaim.vue";
import ClaimV2Service from "@/views/commons/components/claims_v2/services/claims-v2.service";
import ModalDetail from "@/views/commons/components/claims_v2/components/Modals/ModalDetail.vue";
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ModalNewClaim,
    ModalCreateClaim,
    ModalTracking,
    ModalDetail,
    IconStatusAccount,
  },
  props: {
    fromCeDigital: {
      type: Boolean,
      default: false,
    },
    nClientAccountId: {
      type: String,
      required: true,
    },
    accountClient: {
      type: String,
      required: true,
    },
    module: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      isBusy: false,
      ownControl: false,
      historys: [],
      fields: [
        {
          key: "type_claim",
          label: "Details",
          visible: true,
        },
        {
          key: "reasons",
          label: "Reasons",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "advisor",
          label: "Advisor",
          class: "text-center",
          visible: true,
        },
        {
          key: "created_at",
          label: "Created",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "priority",
          label: "Priority",
          class: "text-center",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          visible: true,
        },
        {
          key: "days_passed",
          label: "Days",
          class: "text-center",
          visible: true,
        },
        {
          key: "tracking",
          label: "Tracking",
          class: "text-center",
          visible: true,
        },
        {
          key: "action",
          label: "Actions",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
      ],
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      dataclient: "",
      newdataclient: "",
      showModalClient: false,
      selectedItemClaim: "",
      showModalTracking: false,
      showModalDetailClaim: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      clientInfo: "DebtSolutionClients/G_CLIENTS",
      G_CLAIMS: "DebtSolutionClients/G_CLAIMS",
      clientclaims: "ParagonClientDashboard/getPersonalInputs",
      getRefreshTable: "ClaimsV2Store/getRefreshTable",
    }),
    modulClaim() {
      const { modul_id } = this.currentUser;
      return [16, 18].includes(modul_id) ? null : modul_id;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },

  watch: {
    getRefreshTable(value) {
      /** reload table */
      if (value) {
        this.$refs.updatesChargeListTable.refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },
  created() {
    this.isResponsible();
    this.ownControl = true;
  },

  methods: {
    isResponsible() {
      this.$store.dispatch("NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO", {
        account_id: this.$route.params.idClient,
      });
    },
    ...mapActions({
      A_CLAIMS: "DebtSolutionClients/A_CLAIMS",
      A_CLAIMS_QUALITY: "DebtSolutionClients/A_CLAIMS_QUALITY",
      CHANGE_REFRESH_TABLE: "ClaimsV2Store/CHANGE_REFRESH_TABLE",
    }),

    closeModalHistory() {
      this.$emit("close");
    },
    openCreateNewClaim() {
      this.showModalClient = true;
    },
    closeCreateNewClaim() {
      this.modalCreateNewClaim = false;
    },

    async searchHistoryCoApplicant(ctx) {
      try {
        this.isBusy = true;
        const data = await this.A_CLAIMS_QUALITY({
          tab: "all",
          module_id: this.modulClaim,
          ...ctx,
          perPage: 50,
          nClientAccountId: this.nClientAccountId,
        });
        this.isBusy = false;
        this.historys = data;
        return data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with History!"
        );
        console.log("something went wrong with historyStatus", error);
      }
    },
    async executeSearchClient() {
      try {
        this.addPreloader();
        const { data } = await ClaimV2Service.searchClientForClaim({
          search: this.accountClient,
          moduleId: this.module,
          ...this.paginate,
        });
        this.dataclient = data[0];
        this.removePreloader();
      } catch (ex) {
        this.showErrorSwal(ex);
        console.log(ex);
      } finally {
        this.loadingSearch = false;
      }
    },
    reasonData(item) {
      return JSON.parse(item.reasons);
    },
    closeModalDetail() {
      this.showModalDetailClaim = false;
      this.selectedItemClaim = null;
    },
    openModalDetail(item) {
      this.showModalDetailClaim = true;
      this.selectedItemClaim = item;
    },
    closeTrackingModal() {
      this.showModalTracking = false;
    },
    modalTracking(data) {
      this.claimId = data.claim_id;
      this.advisor = data.advisor;
      this.client = data.client;
      this.showModalTracking = true;
    },
    variantPriority(value) {
      let colorPriority = "secondary";
      switch (value) {
        case "HIGH": {
          colorPriority = "light-danger";
          break;
        }
        case "HALF": {
          colorPriority = "light-warning";
          break;
        }
        case "LOW": {
          colorPriority = "light-success";
          break;
        }
      }
      return colorPriority;
    },
    badgeColor(badge) {
      let badgeClass = {
        PENDING: "light-warning",
        SCALED: "light-primary",
        REACTIVATED: "light-info",
        COMPLETED: "light-success",
        ANNULLED: "light-danger",
      };
      return badgeClass[badge];
    },
  },
  mounted() {
    this.executeSearchClient();
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px !important;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
