<template>
  <div>
    <header-slot>
      <template #actions>
        <header-buttons
          :leadId="getLeadId"
          :has-others-programs="hasPrograms"
          @openShareModal="openModalShare"
          @openModalProgramList="openModalProgramList"
          @openModalSendSms="openModalSendSms"
          @openModalHistorySms="openModalHistorySms"
          @openModalSendEmail="openModalSendEmail"
          @openModalHistoryEmail="openModalHistoryEmail"
          @openModalHistoryClaims="openModalTrackingHistoryClaims"
          @openAddService="openAddService"
          @openModalSendStatement="openModalSendStatement"
          @openModalHistoryStatement="openModalHistoryStatement"
        />
      </template>
    </header-slot>
    <personal-information />
    <modal-share
      v-if="activeModalShare"
      :modal-share="activeModalShare"
      @closeModalShare="closeModalShare"
    />
    <modal-program-list
      v-if="activeModalProgramList"
      :modal-program-list="activeModalProgramList"
      @closeModalProgramList="closeModalProgramList"
    />
    <modal-send-sms
      v-if="activeModalSendSms"
      :modal-send-sms="activeModalSendSms"
      :name-leads="{ name: client, id: accountId }"
      :typesms="0"
      @closeModal="closeModalSendSms"
    />
    <modal-history-sms
      v-if="activeModalHistorySms"
      :active="activeModalHistorySms"
      @close="closeModalHistorySms"
    />
    <modal-send-email
      v-if="activeModalSendEmail"
      :modal-send-email="activeModalSendEmail"
      :name-leads="[{ name: client, id: accountId }]"
      :typesms="0"
      @closeModal="closeModalSendEmail"
    />
    <modal-history-email
      v-if="activeModalHistoryEmail"
      :active="activeModalHistoryEmail"
      @close="closeModalHistoryEmail"
    />

    <modal-tracking-history-claims
      v-if="modalHistoryClaims"
      :n-client-account-id="accountId"
      :account-client="account"
      :module="moduleId"
      @close="closeModalTrackingHistoryClaims"
    />

    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :type-modal="modalData.programs.typeModal"
      :sales-client="saleClientP"
      :programs-all="programsAll"
      :users-services="usersServices"
      @closeModal="hideModalProgram"
      @changeProgram="openModalProgram"
    />

    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />

    <modal-send-statement
      v-if="modalSendStatement"
      :modal-status-report="modalSendStatement"
      @closeModalStatus="closeModalSendStatement"
    />

    <modal-history-statement
      v-if="modalHistoryStatement"
      :active="modalHistoryStatement"
      @close="closeModalHistoryStatement"
    />
  </div>
</template>
<script>
// services
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
// components
// Componenrts
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
// modals
import ModalShare from "@/views/commons/components/clients/dashboard/information-client/modals/ModalShare.vue";
import ModalProgramList from "@/views/commons/components/clients/dashboard/information-client/modals/ModalProgramList.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";

// import ModalTrackingHistoryClaims from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalTrackingHistoryClaims";

import ModalTrackingHistoryClaims from "@/views/commons/components/claims_v2/components/Modals/ModalTrackingHistoryClaims";
import ModalAddService from "@/views/commons/components/digital/modals/ModalAddService.vue";

import ModalSendStatement from "@/views/commons/components/clients/dashboard/information-client/modals/ModalStatusReport.vue";
import ModalHistoryStatement from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryStatement.vue";

// vuex
import { mapActions, mapGetters } from "vuex";
import PersonalInformation from "./components/sections/PersonalInformation.vue";
import ModalHistoryEmail from "./components/modals/ModalHistoryEmail.vue";
import ModalHistorySms from "./components/modals/ModalHistorySms.vue";
import Tabs from "./components/sections/Tabs.vue";
import HeaderButtons from "./components/utils/HeaderButtons.vue";
import HeaderSlot from "./components/utils/HeaderSlot.vue";

export default {
  name: "DashboardBusiness",
  components: {
    HeaderSlot,
    HeaderButtons,
    Tabs,
    ModalShare,
    ModalProgramList,
    ModalSendSms,
    ModalHistorySms,
    ModalSendEmail,
    ModalHistoryEmail,
    PersonalInformation,
    ModalTrackingHistoryClaims,
    ModalAddService,
    BusinessModal,
    CreditExpertsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    ModalSendStatement,
    ModalHistoryStatement,
  },
  data() {
    return {
      isBusy: true,
      activeModalShare: false,
      activeModalProgramList: false,
      activeModalSendSms: false,
      activeModalHistorySms: false,
      activeModalSendEmail: false,
      activeModalHistoryEmail: false,
      modalHistoryClaims: false,
      modalOpenAddService: false,
      validateImg: false,
      event: null,
      detailsClient: {},
      modal: {
        programs: false,
      },
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      typeModal: null,
      modalSendStatement: false,
      modalHistoryStatement: false,
      typeAddChange: null,
    };
  },
  computed: {
    clientId() {
      return this.$route.params.idClient;
    },
    hasPrograms() {
      return !!this.counterPrograms;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      getLeadId: "ParagonClientDashboard/getLeadId",
      counterPrograms: "ParagonClientDashboard/getCounterPrograms",
      client: "ParagonClientDashboard/getClient",
      accountId: "ParagonClientDashboard/getAccountId",
      account: "ParagonClientDashboard/getAccount",
      leadId: "ParagonClientDashboard/getLeadId",
      accountId: "ParagonClientDashboard/getAccountId",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.isResponsible();
    this.isBusy = true;
    await this.start();
    this.validateImg = true;
    this.isBusy = false;
    if (this.$route.matched[0].meta.general) {
      this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
      this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
    }
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  methods: {
    isResponsible() {
      this.$store.dispatch("NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO", {
        account_id: this.$route.params.idClient,
      });
    },
    async start() {
      this.addPreloader();
      this.setMonths();
      this.setYears();
      await Promise.all([
        this.getClientInformation(this.clientId),
        this.getCompanyInformation(this.clientId),
        this.getTaskInformation({
          id: this.clientId,
          user_id: this.currentUser.user_id,
        }),
        this.getNotesData({
          id: this.clientId,
          year: new Date().getFullYear(),
          user_id: this.currentUser.user_id,
        }),
        this.getCharges(this.clientId),
        this.getPayments(this.clientId),
        this.getClientToFiles({ id: this.clientId }),
      ]);
      await this.getScores(this.getLeadId);
      this.removePreloader();
    },
    async openModalSendEmail() {
      this.activeModalSendEmail = true;
    },
    closeModalSendEmail() {
      this.activeModalSendEmail = false;
    },
    async openModalHistorySms() {
      await this.getHistorySms({ id: this.accountId, type: 0 });
      this.activeModalHistorySms = true;
    },
    openModalTrackingHistoryClaims() {
      this.modalHistoryClaims = true;
    },
    closeModalTrackingHistoryClaims() {
      this.modalHistoryClaims = false;
    },
    closeModalHistorySms() {
      this.activeModalHistorySms = false;
    },
    async openModalSendSms() {
      await this.getHistorySms({ id: this.accountId, type: 0 });
      this.activeModalSendSms = true;
    },
    closeModalSendSms() {
      this.activeModalSendSms = false;
    },
    async openModalHistoryEmail() {
      await this.getHistoryEmail(this.clientId);
      this.activeModalHistoryEmail = true;
    },
    closeModalHistoryEmail() {
      this.activeModalHistoryEmail = false;
    },
    openModalProgramList() {
      this.activeModalProgramList = true;
    },
    closeModalProgramList() {
      this.activeModalProgramList = false;
    },
    openModalShare() {
      this.activeModalShare = true;
    },
    closeModalShare() {
      this.activeModalShare = false;
    },

    openModalSendStatement() {
      this.modalSendStatement = true;
    },

    closeModalSendStatement() {
      this.modalSendStatement = false;
    },
    openModalHistoryStatement() {
      this.modalHistoryStatement = true;
    },

    closeModalHistoryStatement() {
      this.modalHistoryStatement = false;
    },

    ...mapActions({
      getClientInformation: "ParagonClientDashboard/getClientData",
      getCompanyInformation: "ParagonClientDashboard/getCompanyData",
      getTaskInformation: "ParagonClientDashboard/getTaskData",
      getNotesData: "ParagonClientDashboard/getNotesData",
      getCharges: "ParagonClientDashboard/getCharges",
      getPayments: "ParagonClientDashboard/getPayments",
      getScores: "ParagonClientDashboard/getScores",
      getHistorySms: "ParagonClientDashboard/getHistorySms",
      getHistoryEmail: "ParagonClientDashboard/getHistoryEmail",
      getClientToFiles: "DebtSolutionClients/A_GET_CLIENTS",

      setMonths: "ParagonClientDashboard/setMonths",
      setYears: "ParagonClientDashboard/setYears",
    }),
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    async createEvent() {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: this.leadId,
      });
      this.event = response.id;
    },
    async openAddService() {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        await this.createEvent();
        const data = {
          lead_name: this.client,
          lead_id: this.leadId,
          accounts: [
            {
              program: "Business",
            },
          ],
        };
        this.modalGeneralService(data, 3);
      }
    },
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    async openModalProgram(data) {
      if (data === undefined) return;
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: this.accountId,
            captured: data.captured.id,
            seller: data.seller.id,
            type: this.typeAddChange,
            user_id: this.currentUser.user_id,
            module: this.moduleId,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            const result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              this.$router
                .push({ name: "sales-made-bussiness-client-pending" })
                .catch((err) => {
                  console.log(err);
                });
              this.removePreloader();
            }
          } catch (error) {
            this.showErrorSwal(
              "Please, verify if the client already has the service, otherwise contact support.",
              "Error"
            );
          } finally {
            this.removePreloader();
          }
        }
      }
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
  },
};
</script>
