<template>
  <b-modal
    v-model="isActive"
    @hidden="closeMe"
    title="History Of Payments"
    title-tag="h3"
    size="xl"
  >
    <program-client-header :client="client" :program="program" />
    <b-table :items="history" :fields="fields">
      <template #cell(monthly_amount)="{ item }">
        <span> $ {{ item.monthly_amount }} </span>
      </template>
      <template #cell(method_payment)="{ item }">
        <span>
          {{ methodPayment(item.method_payment) }}
        </span>
      </template>
      <template #cell(type_payment)="{ item }">
        <span>
          {{ typePayment(item.type_payment) }}
        </span>
      </template>
      <template #cell(created_at)="{ item }">
        <span>
          {{ item.created_at | myGlobalWithHour }}
        </span>
      </template>
      <template #cell(updated_at)="{ item }">
        <span>
          {{ item.updated_at | myGlobalWithHour }}
        </span>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
//components
import ProgramClientHeader from "@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue";
//vuex
import { mapGetters } from "vuex";

export default {
  created() {
    this.isActive = this.active;
  },
  props: { active: { type: Boolean } },
  components: { ProgramClientHeader },
  data() {
    return {
      isActive: false,
      fields: [
        { key: "authorizel_id", label: "Transaction ID" },
        { key: "cardnumber", label: "Card Number" },
        { key: "day_payment", label: "Payment Date" },
        { key: "monthly_amount", label: "Monthly Payment" },
        { key: "method_payment", label: "Payment Method" },
        { key: "type_payment", label: "Payment Type" },
        { key: "creator_name", label: "User" },
        { key: "created_at", label: "Date" },
        { key: "updater_name", label: "Updated By" },
        { key: "updated_at", label: "Updated At" },
      ],
    };
  },
  methods: {
    typePayment(value) {
      switch (parseInt(value)) {
        case 0:
          return "Automatic";
          break;
        case 1:
          return "Manual";
          break;
        default:
          return "-";
          break;
      }
    },
    methodPayment(value) {
      switch (parseInt(value)) {
        case 0:
          return "Credit Card";
          break;
        case 1:
          return "Others";
          break;
        default:
          return "-";
          break;
      }
    },
    closeMe() {
      this.isActive = false;
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters({
      program: "ParagonClientDashboard/getProgram",
      client: "ParagonClientDashboard/getClient",
      history: "ParagonClientDashboard/getHistoryMonthlyPayments",
    }),
  },
};
</script>
