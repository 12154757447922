<template>
  <b-modal
    size="xmd"
    ref="modalCreateClaim"
    title="CREATE CLAIM"
    :no-close-on-backdrop="true"
    :ok-disabled="loading"
    :cancel-disabled="loading"
    :hide-header-close="loading"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
    @hidden="closeMe"
    centered
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <span>Create Claim</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="closeMe"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <b-overlay
      id="overlay-background"
      :show="loading"
      :variant="skin"
      :opacity="0.5"
      blur="2px"
      rounded="sm"
    >
      <b-row class="client-info mx-0">
        <!-- Client -->
        <b-col cols="3" class="p-0">
          <div
            class="d-flex align-items-center justify-content-start"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Client |</p>
            <span class="d-flex ml-1">
              <status-account
                :account="clientData"
                :text="false"
              ></status-account>
              <p>{{ clientData.client }}</p>
            </span>
          </div>
        </b-col>
        <b-col cols="3" class="p-0">
          <div
            class="ml-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Account | {{ clientData.account }}</p>
          </div>
        </b-col>
        <b-col cols="3" class="p-0">
          <div
            class="ml-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Advisor | {{ clientData.advisor || "None" }}</p>
          </div>
        </b-col>
        <b-col cols="3" class="p-0">
          <div
            class="ml-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Sup/Chief | {{ clientData.chief }}</p>
          </div>
        </b-col>
      </b-row>
      <hr />
      <ValidationObserver ref="formObservable" lazy v-bind:vid="'form-cliente'">
        <b-row class="mt-1">
          <b-col cols="12" sm="6" lg="4">
            <ValidationProvider
              v-slot="{ errors }"
              name="advisorSelect"
              rules="required"
            >
              <b-form-group
                id="advisor-select"
                label="Attended by"
                label-for="advisor-select"
              >
                <v-select
                  :options="dataUsersAdvisor"
                  label="name"
                  v-model="formClaim.userAttended"
                  :reduce="(x) => x.id"
                  :searchable="true"
                  placeholder="Select Source"
                  :class="errors[0] ? 'border-danger' : null"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="12" sm="6" lg="4">
            <ValidationProvider
              v-slot="{ errors }"
              name="sourceOfClaim"
              rules="required"
            >
              <b-form-group
                id="options-1"
                label="Source of claim"
                label-for="select-1"
              >
                <v-select
                  :options="dataSourceClaims"
                  label="name"
                  v-model="formClaim.sourceOfClaim"
                  :reduce="(x) => x.id"
                  :searchable="false"
                  placeholder="Select Source"
                  :class="errors[0] ? 'border-danger' : null"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="12" sm="6" lg="4">
            <ValidationProvider
              v-slot="{ errors }"
              name="typeClaim"
              rules="required"
            >
              <b-form-group
                id="options-2"
                label="Type claim"
                label-for="select-2"
              >
                <v-select
                  :options="dataTypeClaims"
                  label="name"
                  v-model="formClaim.typeClaim"
                  :reduce="(x) => x.id"
                  :searchable="false"
                  placeholder="Select Type"
                  :class="errors[0] ? 'border-danger' : null"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <template v-if="formClaim.typeClaim === 3">
            <b-col cols="12" sm="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="reasonForTheClaims"
                rules="required"
              >
                <b-form-group
                  id="amount-client"
                  label="Amount client request"
                  label-for="amount-client"
                >
                  <money
                    v-bind="vMoney"
                    v-model="formClaim.amountClientRequest"
                    class="form-control"
                    :class="errors[0] ? 'is-invalid' : null"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="12" sm="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="reasonForTheClaims"
                rules="required"
              >
                <b-form-group
                  id="amount-departament"
                  label="Amount department suggest"
                  label-for="amount-departament"
                >
                  <money
                    v-bind="vMoney"
                    v-model="formClaim.amountDepartmentSuggest"
                    class="form-control"
                    :class="errors[0] ? 'is-invalid' : null"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </template>

          <b-col cols="12" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              name="reasonForTheClaims"
              rules="required|min:1"
            >
              <b-form-group
                id="options-3"
                label="Reason for the claims"
                label-for="select-3"
              >
                <v-select
                  :options="dataReasonClaims"
                  v-model="formClaim.reasonForTheClaims"
                  :reduce="(reason) => reason.id"
                  label="name"
                  multiple
                  :class="errors[0] ? 'border-2 border-danger' : null"
                  placeholder="Select Reason"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="12" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              name="priority"
              rules="required"
            >
              <b-form-group
                id="options-4"
                label="Priority level"
                label-for="select-4"
              >
                <v-select
                  :options="dataPriority"
                  v-model="formClaim.priority"
                  :searchable="false"
                  placeholder="Select Priority"
                  :class="errors[0] ? 'border-danger' : null"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-group
                id="input-textarea"
                label="Claim description"
                label-for="textarea-description"
              >
                <b-form-textarea
                  v-model="formClaim.description"
                  id="textarea-no-resize"
                  placeholder="Enter claim description"
                  rows="3"
                  no-resize
                  :state="errors[0] ? false : null"
                ></b-form-textarea>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-overlay>
    <template #modal-footer>
      <b-button variant="secondary" @click="closeMe">Cancel</b-button>
      <b-button
        id="save-claim"
        @click="SaveClaim"
        class="background-ce"
        :disabled="loading"
      >
        <feather-icon icon="SaveIcon" class="text-white" size="15" />
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClaimV2Service from "@/views/commons/components/claims_v2/services/claims-v2.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import VMoney from "v-money";
export default {
  components: {
    VMoney,
    StatusAccount,
  },
  props: {
    client: {
      type: Object,
      default: () => undefined,
    },
    account: {
      type: String,
      default: () => undefined,
    },
    clientModule: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      formClaim: {
        sourceOfClaim: null,
        typeClaim: null,
        priority: null,
        reasonForTheClaims: [],
        accountId: null,
        programId: null,
        moduleId: null,
        description: null,
        userId: 0,
        userAttended: null,
        amountClientRequest: 0,
        amountDepartmentSuggest: 0,
      },
      dataUsersAdvisor: [],
      dataSourceClaims: [],
      dataTypeClaims: [],
      dataReasonClaims: [],
      dataPriority: ["HIGH", "HALF", "LOW"],
      clientData: {
        id: "",
        client_id: "",
        advisor: "",
        program: "",
        client: "",
        email: "",
        account: "",
        chief: "",
        status: 0,
        module_id: 0,
        program_id: 0,
        selected: 0,
      },
      showModal: false,
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    client: {
      handler(value) {
        if (value) {
          this.clientData = value;
        }
      },
      deep: true,
      immediate: true,
    },
    account: {
      handler(value) {
        if (value && value != "") {
          this.executeSearchClient();
        }
      },
      deep: true,
      immediate: true,
    },
    "formClaim.typeClaim": {
      handler(value) {
        if (value == 3) {
          this.formClaim.amountClientRequest = 0;
          this.formClaim.amountDepartmentSuggest = 0;
        } else {
          this.$nextTick(() => {
            this.formClaim.amountClientRequest = null;
            this.formClaim.amountDepartmentSuggest = null;
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.toggleModal("modalCreateClaim");
  },
  created() {
    this.getUsersAdvisor();
    this.getDataOptions();
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "ClaimsV2Store/CHANGE_REFRESH_TABLE",
    }),
    closeMe() {
      this.$emit("close");
    },
    saveMe() {
      this.CHANGE_REFRESH_TABLE(true);
      this.$emit("save");
    },
    async SaveClaim() {
      try {
        const destinationRoute = this.$route.matched[1].meta.routeLevelZero;
        const confirm = await this.showConfirmSwal();
        if (!confirm.value) {
          return;
        }
        this.loading = true;
        this.formClaim.accountId = this.clientData.id;
        this.formClaim.moduleId = this.moduleId;
        this.formClaim.programId = this.clientData.program_id;
        this.formClaim.userId = this.currentUser.user_id;
        let valid = await this.$refs.formObservable.validate();
        if (!valid || this.formClaim.reasonForTheClaims.length < 1)
          throw new Error("Fields required");
        const { data } = await ClaimV2Service.store(this.formClaim);
        const { success, message } = data;
        if (!success) throw new Error(message);
        this.loading = false;
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          message
        );
        this.saveMe();
        if (
          this.$route.name !== destinationRoute &&
          this.$route.path.indexOf("claims") !== -1
        ) {
          this.$router.push({ name: destinationRoute });
        }
      } catch (ex) {
        this.loading = false;
        this.showToast("danger", "top-right", "Failed!", "XIcon", ex.message);
      }
    },
    async getDataOptions() {
      try {
        const { data, message, success } =
          await ClaimV2Service.getTableRelatedToClaim();

        if (!success) throw new Error(message);
        const { source_claims, reason_claims, types_claims } = data;
        this.dataReasonClaims = reason_claims;
        this.dataSourceClaims = source_claims;
        this.dataTypeClaims = types_claims;
      } catch (ex) {
        this.showToast("danger", "top-right", "Failed!", "XIcon", ex.message);
      }
    },
    async executeSearchClient() {
      try {
        this.addPreloader();
        const { data } = await ClaimV2Service.searchClientForClaim({
          search: this.account,
          moduleId:
            this.currentUser.modul_id == 7 || this.currentUser.modul_id == 11
              ? this.clientModule
              : this.currentUser.modul_id,
          currentPage: 1,
          perPage: 10,
        });
        this.removePreloader();
        this.clientData = data[0];
      } catch (ex) {
        this.showErrorSwal(ex);
        this.loading = false;
      } finally {
        this.loadingSearch = false;
      }
    },
    async getUsersAdvisor() {
      try {
        const { data } = await ClaimV2Service.getUsersAdvisorForModule(
          this.moduleId
        );
        this.dataUsersAdvisor = data;
      } catch (ex) {
        console.log(ex.message);
      }
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px !important;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
