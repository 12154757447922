<template>
  <b-input-group size="sm" style="margin-bottom: 6px">
    <b-input-group-prepend class="w-20">
      <b-input-group-text class="w-100 badge-name-group">
        <span>{{ tittle }} </span>
      </b-input-group-text>
    </b-input-group-prepend>
    <b-form-input
      v-if="type == 'text'"
      v-model="info"
      :disabled="!edit"
      :type="type"
      @input="input"
      :max="100"
    />
    <b-form-input
      v-if="type == 'number'"
      v-model="info"
      :disabled="!edit"
      @input="input"
      :max="10"
      v-mask="'##-#######'"
    />

    <v-select
      v-if="type == 'select'"
      label="text"
      v-model="info"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="dataSelect"
      @change="input"
      :clearable="false"
      :reduce="(info) => info.value"
      :disabled="!edit"
      class="form-control bg-transparent hardcode-select"
      :class="{ 'text-white': isDarkSkin }"
    />

    <!-- <b-form-input
      v-if="type == 'date'"
      v-model="info"
      :disabled="!edit"
      :type="type"
    /> -->
    <kendo-datepicker
      v-if="type == 'date'"
      v-model="info"
      :format="'MM/dd/yyyy'"
      :disabled="!edit"
      class="form-control bg-transparent hardcode-kendo"
      :class="{ 'text-white': isDarkSkin }"
    />

    <b-form-input
      v-if="type == 'phone'"
      v-model="info"
      :disabled="!edit"
      @input="input"
      v-mask="'(###)-###-####'"
    />
    <b-input-group-append class="w-20">
      <b-button variant="outline-info" v-if="edit" @click="saveChange"
        ><feather-icon icon="SaveIcon"
      /></b-button>
      <b-button :disabled="isResponsibleAccount" variant="outline-warning" v-else @click="editField"
        ><feather-icon icon="Edit3Icon"
      /></b-button>
      <b-button variant="outline-danger" v-if="edit" @click="closeEdit"
        ><feather-icon icon="XIcon"
      /></b-button>
      <b-button
        variant="outline-primary"
        v-else
        :id="`viewButton-${idBtn}`"
        @mouseover="searchTracking"
        @mouseleave="inactivePopOver"
        ><feather-icon icon="EyeIcon"
      /></b-button>
      <b-popover
        :show.sync="popOverIsActive"
        :target="`viewButton-${idBtn}`"
        triggers="hover"
        placement="left"
      >
        <span v-if="trackingCompany === 'empty'"> No records found </span>
        <ul v-else>
          <li
            v-for="(tracking, i) in trackingCompany"
            :key="i"
            class="d-flex flex-column px-1 border-bottom-secondary-1"
          >
            <span>{{ tracking.name_user }}</span
            ><span>{{ tracking.created_at | myGlobalWithHour }}</span>
          </li>
        </ul>
      </b-popover>
    </b-input-group-append>
  </b-input-group>
</template>
<script>
//vuex
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    vSelect,
  },
  props: {
    model: { type: [String, Number] },
    type: { type: String, default: () => "text" },
    dataSelect: { type: Array, default: () => [] },
    tittle: { type: String, default: () => "text" },
  },
  data() {
    return {
      info: "",
      edit: false,
      idBtn: 0,
      popOverIsActive: false,
    };
  },
  created() {
    if (this.idBtn === 0) this.randomNumber();
  },
  watch: {
    model(newval) {
      this.info = newval;
    },
  },
  methods: {
    activePopOver() {
      this.popOverIsActive = true;
    },
    inactivePopOver() {
      this.popOverIsActive = false;
    },
    randomNumber() {
      this.idBtn = Math.round(Math.random() * 10000);
    },
    searchTracking() {
      this.activePopOver();
      this.$emit("hover");
    },
    editField() {
      this.edit = true;
    },
    closeEdit() {
      this.info = this.info != this.model ? this.model : this.info;
      this.edit = false;
    },
    saveChange() {
      this.$emit("save", this.info);
      this.edit = false;
    },
    input() {
      this.$emit("input", this.info);
    },
  },
  computed: {
    ...mapGetters({
      trackingCompany: "ParagonClientDashboard/getTrackingCompanyData",
      currentUser: "auth/currentUser",
    }),
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === this.currentUser.user_id || this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === null);
      }
      return false;
    },
  },
};
</script>

<style>
.w-20 {
  width: 20%;
}
.w-70 {
  width: 70%;
}
.hardcode-kendo .k-picker-wrap {
  border: none !important;
  margin-top: -3px;
}
.hardcode-select .vs__dropdown-toggle {
  border: none;
  background: transparent !important;
  margin-top: -5px;
}
</style>