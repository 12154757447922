<template> 
<div>
    <feather-icon      
                    :icon="status===2?'TriangleIcon':'CircleIcon'"
                    size="13"
                    :fill="colorStatus"
                    :style="{'color':colorStatus}"
                    /> {{textStatus}}
</div>
</template>
<script>
export default {
     props:{ status:{required:true,type:Number},
               },
     data(){return{
          text:'',
     }},
     computed:{
          textStatus(){
              switch(this.status){
                    case 1 :
                         return 'Active'
                    break;
                    case 2 :
                         return 'Hold' 
                    break;
                    case 3 :
                         return 'Transition'
                    break;
                    case 4 :
                         return 'Canceled'
                    break;
                    case 5 :
                         return 'Loyal'
                    break;
                    case 6 :
                         return 'Closed'
                    break;
              }
          },
          colorStatus(){
               let selected
               switch(this.status){
                    case 1 :
                         selected = '#00CC00' //green'
                    break;
                    case 2 :
                         selected = 'yellow'
                    break;
                    case 3 :
                         selected = 'blue'
                    break;
                    case 4 :
                         selected = 'red'
                    break;
                    case 5 :
                         selected = 'red'
                    break;
                    case 6 :
                         selected = 'red'
                    break;
               }
               return selected
          }
     },
               
}
</script>